import React from 'react'
import { InternalTrasactionReportTable } from './partials/InternalTrasactionReport'

export const InternalTransactionReport = () => {

    return (
        <div>
            <InternalTrasactionReportTable />
        </div>
    )
}
