import React from 'react'
import { TopTitle } from '../../../../Components/Form/TopTitle'
import { CustomCardView } from '../../../../Components/CustomCardView'
import { ViewBankContainer } from './Partials/ViewBankContainer'

const View_Bank_Details = () => {
    return (
        <div>
            <ViewBankContainer />
        </div>
    )
}

export default View_Bank_Details