export const GSTPercentage = [
    {
        label:'5%',
        value:5,
    },
    {
        label:'12%',
        value:12,
    },
    {
        label:'18%',
        value:18,
    },
    {
        label:'28%',
        value:28,
    },
]